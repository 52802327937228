import React, { Component } from 'react'
import classnames from 'classnames'

import logo from '../../images/logo/logo-dark.png'

import './navigation.scss'

export default class Navigation extends Component {

  state = {
    open: false
  }

  toggleDrawer = () => {
    this.setState({
      open: !this.state.open
    }, () => {
      if (this.state.open) {
        document.body.classList.add('navigation-body-scoll-disabled')
      } else {
        document.body.classList.remove('navigation-body-scoll-disabled')
      }
    })
  }

  render() {
    const { children } = this.props
    const { open } = this.state

    return (
      <div
        className={classnames("navigation", {
          "navigation-open": open
        })}
      >
        <div className="navigation-drawer">
          <div className="navigation-drawer-content">
            <div className="navigation-pages">
              <a href="/">Home</a>
              <a href="/principles">Principles</a>
              <a href="http://www.plangrid.com/careers" target="_blank">Careers</a>
            </div>
            <div className="navigation-social">
              <a href="https://medium.com/plangrid-design" target="_blank">Medium</a>
              <a href="https://instagram.com/plangriddesign" target="_blank">Instagram</a>
              <a href="https://twitter.com/plangrid" target="_blank">Twitter</a>
            </div>
          </div>
          <button className="navigation-drawer-button" onClick={this.toggleDrawer}>
            <div className="navigation-drawer-button-icon"></div>
          </button>
        </div>
        <div className="navigation-content-container">
          <div className="navigation-content">
            {children}
          </div>
          <img src={logo} className="navigation-header" />
        </div>
      </div>
    )
  }
}
