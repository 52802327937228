import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import logo from "../../images/logo/logo-white.png"
import instagram from "../../images/social/logo-instagram.svg"
import medium from "../../images/social/logo-medium.svg"
import dribbble from "../../images/social/logo-dribbble.svg"

import './footer.scss'

const Footer = () => (
  <div className="footer">
    <div className="container">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
        <div className="footer-copyright">
          <span className="footer-credits">© 2018 PlanGrid Inc.</span>
          <Link to="/" className="footer-back-to-top">Back to top</Link>
        </div>
      </div>
      <div className="social">
        <a to="instagram.com/plangridesign" target="_blank">
          <div className="icon">
            <img src={instagram} alt="Instagram" />
          </div>
        </a>
        <a to="medium.com/plangrid-design" target="_blank">
          <div className="icon">
            <img src={medium} alt="Instagram" />
          </div>
        </a>
        <a to="dribbble.com/plangridesign" target="_blank">
          <div className="icon">
            <img src={dribbble} alt="Instagram" />
          </div>
        </a>
      </div>
      <div className="nav">
       <Link to="/">Home</Link>
       <Link to="/principles">Principles</Link>
       <Link to="http://plangrid.com/careers" target="_blank">Open Roles</Link>
      </div>
    </div>
  </div>
)

export default Footer
